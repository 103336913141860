import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Typeform } from "@swizec/gatsby-theme-course-platform";
export const title = "Find Your Idea intro";
export const description = "";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "welcome-to-find-your-idea"
    }}>{`Welcome to Find Your idea`}</h1>
    <p>{`Hi, welcome to the Find Your Idea self-paced workshop`}</p>
    <p>{`This workshop is a series of exercises that walk you through a framework for finding, validating, and qualifying ideas so you never have to worry about working on the wrong thing.`}</p>
    <p>{`It works like this:`}</p>
    <ol>
      <li parentName="ol">{`Watch a video that sets the scene`}</li>
      <li parentName="ol">{`Try to solve the problem`}</li>
      <li parentName="ol">{`See my solution and explanation of how I got there`}</li>
    </ol>
    <p>{`But first, why did you buy this workshop?`}</p>
    <Typeform url="https://swizecteller.typeform.com/to/V2UNlk" mdxType="Typeform" />
    <h2 {...{
      "id": "login-instructions"
    }}>{`Login instructions`}</h2>
    <p>{`You'll need to login to see other pages.`}</p>
    <p>{`Click "Student Login" top right.`}</p>
    <p>{`My robots created a temporary password for you. Nobody knows the password. Yay security.`}</p>
    <p><strong parentName="p">{`Your email is your username`}</strong>{`. Robots used the email you purchased with.`}</p>
    <p>{`Find Your Idea uses the same account as my other courses.`}</p>
    <p>{`If you're new, use the password reset flow. You'll end up back on the workshop site, logged in. Use the email/pass combination to login anywhere.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      